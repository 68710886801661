import React, { Component } from 'react'
import axios from 'axios'

// import components (rows)
import TitleContainer from './DetailComponents/TitleContainer'
import BriefContainer from './DetailComponents/BriefContainer'
import LargeImageContainer from './DetailComponents/LargeImageContainer'
import StrategyContainer from './DetailComponents/StrategyContainer'
import ExperienceContainer from './DetailComponents/ExperienceContainer'
import ResultContainer from './DetailComponents/ResultContainer'
import LoadOverlay from './LoadOverlay'
import TechContainer from './DetailComponents/TechComponents/TechContainer'
import LogoFooter from './LogoFooter'

// import styles
export class DetailDisplay extends Component {
    constructor(props){
      super(props)

      this.state = {
        mobile: false,
        loading: true,
        brief: {},
        experience: [],
        result: [],
        strategy: [],
        title: {},
        images: {},
        text: [],
        showTech: true,
        masterColor: "#E06647"
      }
    }  

    componentDidMount = () => {
      window.addEventListener('resize', this.handleResize.bind(this))
      var mobile = false
      if(window.innerWidth < 500){
        mobile = true
      }
      sessionStorage.setItem("prevLocation", this.props.detailId)
      let detailObj = {
        id: this.props.detailId
      }
      axios.post('/getDetails', detailObj).then(res => {
        if(res.data.masterColor !== "#244224"){
        this.setState({
          mobile: mobile,
          brief: res.data.brief,
          experience: res.data.experience,
          result: res.data.result,
          strategy: res.data.strategy,
          title: res.data.title,
          images: res.data.imageRefs,
          text: res.data.largeText,
          tech: res.data.tech,
          loading: false,
          showTech: res.data.showTech,
          masterColor: res.data.masterColor
        })
        } else {
        this.setState({
          brief: res.data.brief,
          title: res.data.title,
          images: res.data.imageRefs,
          text: res.data.largeText,
          loading: false,
          masterColor: res.data.masterColor,
          result: res.data.result,
          mobile: mobile
        })
        console.log(this.state.title.title)
        }

      })
    }

          handleResize = () => {
           let isMobile;
           if(window.innerWidth <= 500){
            isMobile = true
           } else {
             isMobile = false
           }
           this.setState({
             mobile: isMobile
           })
        }

    render() {
        return (
          <React.Fragment>
          <div className="displayContainer" style={{backgroundColor: '#fafafa'}}>
            {this.state.loading === true ? <LoadOverlay /> : null}
            <TitleContainer
              title={this.state.title.title}
              text={this.state.title.description}
              image={this.state.images.title}
              color={this.state.masterColor}
            />
             {this.state.mobile === false ? <LargeImageContainer image={this.state.images.largeOne} />  : null}
            <BriefContainer
              text={this.state.text[0]}
              timeline={this.state.brief.timeline}
              type={this.state.brief.type}
              roles={this.state.brief.roles}
            />
            <div id="shrinkSizes">
            <LargeImageContainer image={this.state.images.largeTwo} />
            </div>
            {this.state.masterColor === "#244224" ? <ResultContainer resultInfo={this.state.result} title={"What we did."} />  : 
            <React.Fragment>
            <StrategyContainer masterColor={this.state.masterColor} info={this.state.strategy} />
            <div id="shrinkSizes">
              <LargeImageContainer image={this.state.images.largeThree} />
            </div>
            <ExperienceContainer experienceInfo={this.state.experience} />
            <div style={{width: '100%', borderBottom: '1.5px solid black'}} />
            {this.state.showTech === true && this.state.isMobile === false ? <TechContainer info={this.state.tech} /> : null}
            <ResultContainer resultInfo={this.state.result} title={"Tools."} /> 
            </React.Fragment>}
            </div>
          {this.state.loading === false ? <LogoFooter /> : null}
          </React.Fragment>
        );
    }
}

export default DetailDisplay
